.flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-box{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.img-cir{
    border-radius: 50%;
    object-fit: cover;
}